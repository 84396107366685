import classnames from 'classnames';
import React, { Component, Fragment } from 'react';

import { serviceImg1, serviceImg2, serviceImg3, serviceImg4 } from '../../constants/images';
import styles from './IntroStyle.module.scss';
import TapContent from './TapContent';
class Section3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tapData: [
        {
          key: 'mobile_contract',
          value: MESSAGE.MOBILE_CONTRACT,
          content: `${MESSAGE.INTRO_MOBILE_CONTRACT_CONTENT1} ${MESSAGE.INTRO_MOBILE_CONTRACT_CONTENT2} ${MESSAGE.INTRO_MOBILE_CONTRACT_CONTENT3} ${MESSAGE.INTRO_MOBILE_CONTRACT_CONTENT4}`,
          images: serviceImg1,
        },
        {
          key: 'license_cert',
          value: MESSAGE.CAR_LICENSE_FACT_YN,
          content: `${MESSAGE.INTRO_CAR_LICENSE_CONTANT1} ${MESSAGE.INTRO_CAR_LICENSE_CONTANT2}`,
          images: serviceImg2,
        },
        {
          key: 'car_year_search',
          value: MESSAGE.SEARCH_CAR_OLD,
          content: `${MESSAGE.INTRO_CAR_OLD_CONTANT1} ${MESSAGE.INTRO_CAR_OLD_CONTANT2} ${MESSAGE.INTRO_CAR_OLD_CONTANT3}`,
          images: serviceImg3,
        },
        {
          key: 'claim_system',
          value: MESSAGE.CLAIM_SYSTEM,
          content: `${MESSAGE.INTRO_CLAIM_SYSTEM_CONTACT1} ${MESSAGE.INTRO_CLAIM_SYSTEM_CONTACT2}`,
          images: serviceImg4,
        },
      ],
      images: null,
      currentIndex: 0,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const WOW = require('wowjs');

      new WOW.WOW().init();
    }

    this.setInterver();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  setInterver = () => {
    this.interval = setInterval(() => {
      const nextIndex =
        this.state.currentIndex === this.state.tapData.length - 1 ? 0 : this.state.currentIndex + 1;

      this.changeIndex(nextIndex);
    }, 4000);
  };

  changeIndex = (index) => {
    this.setState({ currentIndex: index }, () => {
      clearInterval(this.interval);
      this.setInterver();
    });
  };

  render() {
    return (
      <section className={styles.section3}>
        <div className={styles.section3_container}>
          <div className={styles.section3_area}>
            <div className={styles.section3_images_wrap}>
              <img
                className={classnames([styles.mobile_image, 'wow fadeInUp'])}
                src={this.state.tapData[this.state.currentIndex].images}
              />
            </div>
            <div className={styles.section3_text_area}>
              <div className={styles.section3_title_wrap}>
                <div className={classnames([styles.section3_title, 'wow fadeInUp'])}>
                  핸드폰만 있으면 어디서든
                </div>
                <div
                  className={classnames([styles.section3_title, 'wow fadeInUp'])}
                  data-wow-delay="0.25s"
                >
                  업무가 가능합니다.
                </div>
              </div>
              <TapContent
                data={this.state.tapData}
                changeIndex={this.changeIndex}
                currentIndex={this.state.currentIndex}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Section3;
