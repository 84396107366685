import 'react-datepicker/dist/react-datepicker.css';

import { inject, observer } from 'mobx-react';
import { withRouter } from 'next/router';
import React, { Component } from 'react';

import AppStoreSection from './AppStoreSection';
import FooterBar from './FooterBar';
import FooterSection from './FooterSection';
import styles from './IntroStyle.module.scss';
import LoginSection from './LoginSection';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
@withRouter
@inject('authStore')
@observer
class Intro extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const WOW = require('wowjs');
    const wow = new WOW.WOW();

    wow.init();
    window.dataLayer = window.dataLayer || [];

    function gtag() {
      window.dataLayer.push(arguments);
    }

    gtag('js', new Date());
    gtag('config', 'WJs0zQVjQ9WdtkHbxi34Ew', { optimize_id: 'OPT-PPFFJRV' });
  }

  renderItem = () => {
    return (
      <div className={styles.intro_container}>
        <LoginSection start={this.start} />
        <Section2 />
        <Section3 />
        <Section4 />
        <AppStoreSection />
        <FooterSection />
        <FooterBar />
      </div>
    );
  };

  render() {
    return <div style={{ width: '100%', height: '100%' }}>{this.renderItem()}</div>;
  }
}

export default Intro;
