import classnames from 'classnames';
import React, { Component } from 'react';

import styles from './TapContent.module.scss';

class TapContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  select = (index) => {
    if (!this.props.data || this.props.data.length === 1) return null;

    this.props.changeIndex(index);
  };

  render() {
    return (
      <div className={styles.intro_tap_wrap}>
        <div className={styles.intro_tap}>
          {Boolean(this.props.data) &&
            this.props.data.map((item, i) => {
              return (
                <div
                  key={i}
                  className={classnames([styles.intro_tap_label, 'wow fadeInRight'])}
                  data-wow-delay="0.25s"
                  data-select={this.props.currentIndex === i}
                  data-cursor={Boolean(Boolean(this.props.data) && this.props.data.length !== 1)}
                  onClick={() => this.select(i)}
                >
                  {item.value}
                </div>
              );
            })}
        </div>
        <div className={classnames([styles.intro_tap_content, 'wow fadeInRight'])}>
          {this.props.data[this.props.currentIndex].content}
        </div>
        <div className={styles.intro_tap_paging_wrap}>
          {Boolean(this.props.data) &&
            this.props.data.length > 1 &&
            this.props.data.map((item, i) => {
              return (
                <div
                  key={i}
                  className={
                    this.props.currentIndex === i
                      ? styles.intro_tap_paging_active_button
                      : styles.intro_tap_paging_inactive_button
                  }
                  onClick={() => this.select(i)}
                />
              );
            })}
        </div>
      </div>
    );
  }
}

export default TapContent;
