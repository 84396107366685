import AnimatedNumber from 'animated-number-react';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import apiEtc from '../../api/etc';
import costComma from '../../helpers/costComma';
import styles from './IntroStyle.module.scss';

@inject('authStore')
@inject('menuStore')
@observer
class Section2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyCount: 0,
      contractCount: 0,
      totalClaimCost: 0,
      isView: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', (e) => this.handleScroll(e));
    this.rencarServiceState();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  rencarServiceState = async () => {
    try {
      const response = await apiEtc.getCurrentImsformStatus({});
      const responseData = response.data;

      if (response.status === 200) {
        this.setState({
          companyCount: responseData.company_count,
          contractCount: responseData.contract_count,
          totalClaimCost: responseData.sum_claim_total_cost,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleScroll(e) {
    const IE_SCROLL_Y_OFFSET = 260;
    const window = e.currentTarget;
    const scrollY = window.pageYOffset;
    const startScrollY = window.scrollY ? 200 : IE_SCROLL_Y_OFFSET + 200;
    const endScrollY = window.scrollY ? 1310 : IE_SCROLL_Y_OFFSET + 1310;

    if (scrollY > startScrollY && scrollY < endScrollY) {
      this.setState({ isView: true });
    }
  }

  formatValue = (value) => {
    return costComma(value.toFixed());
  };

  render() {
    return (
      <section className={styles.section2}>
        <div className={styles.section2_container}>
          <div className={styles.section2_area}>
            <div className={styles.section2_text_area}>
              <div className={styles.section2_title_wrap}>
                <div className={classnames([styles.section2_title, 'wow fadeInUp'])}>
                  이미 많은 업체가
                </div>
                <div
                  className={classnames(styles.section2_title, 'wow fadeInUp')}
                  data-wow-delay="0.25s"
                >
                  함께하고 있습니다.
                </div>
              </div>
              <div className={classnames([styles.section2_text_wrap, 'wow fadeInRight'])}>
                <div className={styles.section2_text}>IMS form은 렌터카 업체의 업무를</div>
                <div className={styles.section2_text}>좀 더 편리하고 정확하게 도와드립니다.</div>
              </div>
            </div>
            <div className={classnames([styles.section2_card_area, 'wow fadeIn'])}>
              <div className={styles.section2_card}>
                <div className={styles.section2_card_row}>
                  <label>폼 사용 업체 수</label>
                  <div>
                    <AnimatedNumber
                      value={this.state.isView ? this.state.companyCount : 0}
                      formatValue={this.formatValue}
                    />
                    <span>개 +</span>
                  </div>
                </div>
              </div>
              <div className={styles.section2_card}>
                <div className={styles.section2_card_row}>
                  <label>계약서 작성 건 수</label>
                  <div>
                    <AnimatedNumber
                      value={this.state.isView ? this.state.contractCount : 0}
                      formatValue={this.formatValue}
                    />
                    <span>개 +</span>
                  </div>
                </div>
              </div>
              <div className={styles.section2_card}>
                <div className={styles.section2_card_row}>
                  <label>총 청구 금액</label>
                  <div>
                    <AnimatedNumber
                      value={this.state.isView ? this.state.totalClaimCost : 0}
                      formatValue={this.formatValue}
                    />
                    <span>억 +</span>
                  </div>
                </div>
              </div>
              <div className={styles.section2_data_month}>현재 기준</div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Section2;
