import { withRouter } from 'next/router';
import React, { Component } from 'react';

import styles from './IntroStyle.module.scss';

@withRouter
class FooterBar extends Component {
  render() {
    return (
      <div className={styles.footer_bar_container}>
        <div className={styles.footer_content}>
          <div className={styles.footer_text}>
            &quot; 당신의 사업을 더욱 쉽고 편리하게, 지금 바로 IMS form을 만나보세요! &quot;
          </div>
          <button
            className={styles.button_start}
            onClick={() => this.props.router.push('/registerUser')}
          >
            지금 시작하기
          </button>
        </div>
      </div>
    );
  }
}

export default FooterBar;
