import classnames from 'classnames';
import React, { Component, Fragment } from 'react';

import { btnAppstore, btnGoogleplay, outroBgImg } from '../../constants/images';
import styles from './IntroStyle.module.scss';

class AppStoreSection extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section
        className={styles.app_store_section}
        style={{ backgroundImage: `url(${outroBgImg})` }}
      >
        <div className={styles.app_store_container}>
          <div className={styles.app_store_area}>
            <div className={styles.app_store_text_area}>
              <div className={styles.app_store_title_wrap}>
                <div className={classnames([styles.app_store_title, 'wow fadeInRight'])}>
                  INTELLIGENT
                </div>
                <div
                  className={classnames([styles.app_store_title, 'wow fadeInLeft'])}
                  data-wow-delay="0.5s"
                >
                  MOBILITY
                </div>
                <div
                  className={classnames([styles.app_store_title, 'wow fadeInRight'])}
                  data-wow-delay="1s"
                >
                  SYSTEM
                </div>
              </div>
            </div>
            <div className={styles.app_store_download_wrap}>
              <div className={styles.app_store_explain_wrap}>
                <div className={classnames([styles.app_store_explain_text, 'wow fadeIn'])}>
                  당신의 사업을 더욱 쉽고 편리하게,
                </div>
                <div
                  className={classnames([styles.app_store_explain_text, 'wow fadeIn'])}
                  data-wow-delay="0.25s"
                >
                  지금 바로 IMS form을 만나보세요!
                </div>
              </div>
              <div className={styles.app_store_button_wrap}>
                <button
                  className={classnames([styles.app_store_button, 'wow flipInX'])}
                  style={{ backgroundImage: `url(${btnGoogleplay})` }}
                  onClick={() =>
                    window.open('https://play.google.com/store/apps/details?id=com.rencarform')
                  }
                />
                <button
                  className={classnames([styles.app_store_button, 'wow flipInX'])}
                  style={{ backgroundImage: `url(${btnAppstore})` }}
                  onClick={() =>
                    window.open(`https://itunes.apple.com/kr/app/IMS-form/id1413102300`)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default AppStoreSection;
