import classnames from 'classnames';
import React, { Component, Fragment } from 'react';

import { serviceImg5 } from '../../constants/images';
import styles from './IntroStyle.module.scss';
import TapContent from './TapContent';

class Section4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tapData: [
        {
          key: 'group_and_car_manage',
          value: '지점 및 차량관리',
          content:
            '지점의 차량과 본사의 차량을 한눈에 빠르게 확인해 차량의 유동성을 증대시키고, 실시간으로 지점 계약서를 확인 할 수 있어 빠른 청구가 가능합니다.',
          images: serviceImg5,
        },
      ],
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const WOW = require('wowjs');

      new WOW.WOW().init();
    }

    if (!this.state.images) {
      this.setState({ images: this.state.tapData[0].images });
    }
  }

  render() {
    return (
      <section className={styles.section4}>
        <div className={styles.section4_container}>
          <div className={styles.section4_area}>
            <div className={styles.section4_text_area}>
              <div className={styles.section4_title_wrap}>
                <div className={classnames([styles.section4_title, 'wow fadeInUp'])}>
                  본사에서 실시간
                </div>
                <div
                  className={classnames([styles.section4_title, 'wow fadeInUp'])}
                  data-wow-delay="0.25s"
                >
                  지점 관리가 가능합니다.
                </div>
              </div>
              <TapContent data={this.state.tapData} currentIndex={0} />
            </div>
            <div className={styles.section4_images_wrap}>
              <img
                className={classnames([styles.mobile_image, 'wow fadeInUp'])}
                src={this.state.images}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Section4;
