import classnames from 'classnames';
import React, { Component, Fragment } from 'react';

import { serviceImg6 } from '../../constants/images';
import styles from './IntroStyle.module.scss';
import TapContent from './TapContent';

class Section5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tapData: [
        {
          key: 'rent_company_support',
          value: '업체 지원 사업',
          content:
            '렌터카 업체 간 차량을 사고팔 수 있는 양수도 게시판, 업체의 신용등급 파악 후 가산점을 부여해 진행하는 할부 금융 사업, 특별한 금액에 신차를 판매하고 있는 신차 판매 등 IMS form은 함께 성장할 수 있게 솔루션을 개발하고 공유합니다.',
          images: serviceImg6,
        },
      ],
      images: null,
    };
  }

  componentDidMount() {
    if (!this.state.images) {
      this.setState({ images: this.state.tapData[0].images });
    }
  }

  render() {
    return (
      <section className={styles.section5}>
        <div className={styles.section5_container}>
          <div className={styles.section5_area}>
            <div className={styles.section5_images_wrap}>
              <img
                className={classnames([styles.mobile_image, 'wow fadeInUp'])}
                src={this.state.images}
              />
            </div>
            <div className={styles.section5_text_area}>
              <div className={styles.section5_title_wrap}>
                <div className={classnames([styles.section5_title, 'wow fadeInUp'])}>
                  업체 지원 사업으로
                </div>
                <div
                  className={classnames(styles.section5_title, 'wow fadeInUp')}
                  data-wow-delay="0.25s"
                >
                  함께 성장합니다.
                </div>
              </div>
              <TapContent data={this.state.tapData} currentIndex={0} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Section5;
