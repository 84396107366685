import Link from 'next/link';
import React, { Component, Fragment } from 'react';

import { btnSnsBlog, btnSnsCafe } from '../../constants/images';
import styles from './IntroStyle.module.scss';
class FooterSection extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    // NEXT: 렌카 실서버 처럼 이용약관 옆에 | 있어야함
    return (
      <section className={styles.footer_section}>
        <div className={styles.footer_section_container}>
          <div className={styles.footer_section_area}>
            <div className={styles.footer_company_term_area}>
              <div className={styles.footer_company_term_container}>
                <Link href="/serviceTerms/termOfService" target="_blank">
                  <div className={styles.footer_company_term}>{MESSAGE.USE_TERM}</div>
                </Link>
                <Link href="/serviceTerms/termOfLocationService" target="_blank">
                  <div className={styles.footer_company_term}>{MESSAGE.LOCATION_SERVICE_TERM}</div>
                </Link>
                <Link href="/serviceTerms/privacyTreat" target="_blank">
                  <div className={styles.footer_company_term}>{MESSAGE.PRIVACY_DATA_TERM}</div>
                </Link>
                <Link href="/serviceTerms/termForCustomer" target="_blank">
                  <div className={styles.footer_company_term}>{MESSAGE.CUSTOMER_TERM}</div>
                </Link>
              </div>
              <div className={styles.footer_naver_link}>
                <a target="_blank" href="https://blog.naver.com/rencars">
                  <img src={btnSnsBlog} />
                </a>
                <a target="_blank" href="https://cafe.naver.com/imsform">
                  <img src={btnSnsCafe} />
                </a>
              </div>
            </div>
            <div className={styles.footer_company_info}>
              <div>{MESSAGE.RENCAR_COMPANY}</div>
              <div>{MESSAGE.RENCAR_ADDRESS}</div>
              <div>
                {MESSAGE.RENCAR_BOSS_NAME} | {MESSAGE.BUSSINESS_LICENSE_NUMBER} |{' '}
                {MESSAGE.SALE_NUMBER}
              </div>
              <div>고객센터 1600-8348 (평일 10:00 - 18:00)</div>
              <div>{MESSAGE.COPY_RIGHT}</div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default FooterSection;
